import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Form,
  Input,
  Button,
  Select
} from 'antd'
import { Container, Col, Row, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

class ChangeNotificationDetail extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      applicant: undefined,
      title: undefined,
      createdRequest: undefined,
      createdCode: undefined,
      modificationRequest: undefined,
      modificationVersion: undefined,
      annulmentRequest: undefined,
      annulmentCode: undefined,
      annulmentVersion: undefined,
      description: undefined,
      evaluation: undefined,
      conclusions: undefined,

      type: undefined,
      process: undefined,
      by: undefined,
      rejection: undefined,
      reprocessing: undefined,
      reclassification: undefined,
      concession: undefined,
      descriptionTreatment: undefined,
      documents: [],

      typeRequirement: undefined,
      status: undefined,
      observation: undefined,
      user: undefined,
      errorDocuments: undefined,
      redirect: undefined,
      fullName: undefined,
      date: undefined
    }
    this.state = this.initialState
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async onBack () {
    this.updateState('redirect', '/change-modification')
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  async getChangeNotification () {
    const record = await API.changeModification.detail(this.state.id)
    if (record._id) {
      this.setState({
        applicant: record.applicant,
        title: record.title,
        createdRequest: record.created_request,
        createdCode: record.created_code,
        modificationRequest: record.modification_request,
        modificationVersion: record.modification_version,
        annulmentRequest: record.annulment_request,
        annulmentCode: record.annulment_code,
        annulmentVersion: record.annulment_version,
        description: record.description,
        documents: record.documents,
        evaluation: record.evaluation,
        conclusions: record.conclusions,
        isEdit: record.user._id !== this.state.idUser,
        isClose: record.isClose,
        treatmentUser: record.treatmentUser,
        userSolucionador: record.userSolucionador,
        fullName: `${record.user.firstName} ${record.user.lastName}`,
        date: this.formatDate(record.created)
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getChangeNotification()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    let documents = []
    this.state.documents.map((value, index) => {
      documents.push(<ul key={index}><li><a href={`https://api.oftalmolasersa.com.co/${value[0]}`}>{value[0]}</a></li></ul>)
    })
    return (
      <Layout className='layout'>
        <Header item='changeModification' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Detalle de la solicitud de producto o servicio no conforme</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <label>Código: C-FO-130</label>
                      </Col>
                      <Col>
                        <label>Versión: 2</label>
                      </Col>
                      <Col>
                        <label>Vigencia: Diciembre de 2012</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Solicitante*'
                          validateStatus={this.state.errorApplicant && ('error')}
                          help={this.state.errorApplicant}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled
                            onChange={(value) => this.setState({'applicant': value})}
                            value={this.state.applicant}
                            placeholder='Seleccione un solicitante'
                          >
                            <Select.Option value='ADRIANA LORENA RAMIREZ BARRERA'>ADRIANA LORENA RAMIREZ BARRERA</Select.Option>
                            <Select.Option value='CARLA FERNANDA CHILA MORENO'>CARLA FERNANDA CHILA MORENO</Select.Option>
                            <Select.Option value='CLAUDIA VARGAS'>CLAUDIA VARGAS</Select.Option>
                            <Select.Option value='DIANA CONSTANZA PERDOMO FARFAN'>DIANA CONSTANZA PERDOMO FARFAN</Select.Option>
                            <Select.Option value='ERIKA JULIETH VARGAS ROJAS'>ERIKA JULIETH VARGAS ROJAS</Select.Option>
                            <Select.Option value='JENNY MARCELA CALDERON'>JENNY MARCELA CALDERON</Select.Option>
                            <Select.Option value='LIDA CONSTANZA AGUIRRE'>LIDA CONSTANZA AGUIRRE</Select.Option>
                            <Select.Option value='MARIA CAMILA CASTILLO FARFAN'>MARIA CAMILA CASTILLO FARFAN</Select.Option>
                            <Select.Option value='MARIA YANETH CALDON'>MARIA YANETH CALDON</Select.Option>
                            <Select.Option value='MARITZA ANDREA DIAZ MONTEALEGRE'>MARITZA ANDREA DIAZ MONTEALEGRE</Select.Option>
                            <Select.Option value='MARITZA FERNANDA QUILA MOYANO'>MARITZA FERNANDA QUILA MOYANO</Select.Option>
                            <Select.Option value='NORMA DERLY RODRIGUEZ NAVARRO'>NORMA DERLY RODRIGUEZ NAVARRO</Select.Option>
                            <Select.Option value='PAOLA ANDREA VASQUEZ LASSO'>PAOLA ANDREA VASQUEZ LASSO</Select.Option>
                            <Select.Option value='SANDRA SANCHEZ'>SANDRA SANCHEZ</Select.Option>
                            <Select.Option value='SULLY XIMENA TORRES LAVAO'>SULLY XIMENA TORRES LAVAO</Select.Option>
                            <Select.Option value='YINA MILDRED CUELLAR'>YINA MILDRED CUELLAR</Select.Option>
                            <Select.Option value='YOLANDA PINTO CHAGUALA'>YOLANDA PINTO CHAGUALA</Select.Option>
                            <Select.Option value='YUDDY ARAGONEZ'>YUDDY ARAGONEZ</Select.Option>
                            <Select.Option value='ANA MERCEDESDELGADO PATARROYO'>ANA MERCEDESDELGADO PATARROYO</Select.Option>
                            <Select.Option value='EDNA MARIA SANCHEZ'>EDNA MARIA SANCHEZ</Select.Option>
                            <Select.Option value='LEIDY JOHANA MORALES'>LEIDY JOHANA MORALES</Select.Option>
                            <Select.Option value='ESPERANZA GOMEZ'>ESPERANZA GOMEZ</Select.Option>
                            <Select.Option value='AVILES LONDOÑO LINA MARIA'>AVILES LONDOÑO LINA MARIA</Select.Option>
                            <Select.Option value='BRAVO GONZALEZ CAROLINA'>BRAVO GONZALEZ CAROLINA</Select.Option>
                            <Select.Option value='CARDOZO TORRES LUISA FERNANDA'>CARDOZO TORRES LUISA FERNANDA</Select.Option>
                            <Select.Option value='CELIS VARGAS LUISA FERNANDA'>CELIS VARGAS LUISA FERNANDA</Select.Option>
                            <Select.Option value='ASESOR DE SEGURIDAD Y SALUD EN EL TRABAJO'>ASESOR DE SEGURIDAD Y SALUD EN EL TRABAJO</Select.Option>
                            <Select.Option value='GALINDO CARDOZO CRISTIAN'>GALINDO CARDOZO CRISTIAN</Select.Option>
                            <Select.Option value='PERDOMO PEREZ ALEJANDRA'>PERDOMO PEREZ ALEJANDRA</Select.Option>
                            <Select.Option value='RONAR DARIO OROZCO ULCHUR'>RONAR DARIO OROZCO ULCHUR</Select.Option>
                            <Select.Option value='RUIZ MONROY DANIEL ANDRES'>RUIZ MONROY DANIEL ANDRES</Select.Option>
                            <Select.Option value='SANCHEZ MORALES EDNA MARIA'>SANCHEZ MORALES EDNA MARIA</Select.Option>
                            <Select.Option value='LEIVIN MARIA BLANCO'>LEIVIN MARIA BLANCO</Select.Option>
                            <Select.Option value='LUIS EDUARDO PALOMARES'>LUIS EDUARDO PALOMARES</Select.Option>
                            <Select.Option value='GLORIA CAROLINA QUESADA'>GLORIA CAROLINA QUESADA</Select.Option>
                            <Select.Option value='KAROL PAOLA CONDE'>KAROL PAOLA CONDE</Select.Option>
                            <Select.Option value='GISELLE DANIELA HERNANDEZ'>GISELLE DANIELA HERNANDEZ</Select.Option>
                            <Select.Option value='KELLY FERNANDA CERQUERA'>KELLY FERNANDA CERQUERA</Select.Option>
                            <Select.Option value='KAREN LOZANO'>KAREN LOZANO</Select.Option>
                            <Select.Option value='LAURA NATALIA MENDEZ'>LAURA NATALIA MENDEZ</Select.Option>
                            <Select.Option value='LEIVIN MARIA BLANCO'>LEIVIN MARIA BLANCO</Select.Option>
                            <Select.Option value='JHON JAMES URBANO'>JHON JAMES URBANO</Select.Option>
                            <Select.Option value='KARLA SOLANO'>KARLA SOLANO</Select.Option>
                            <Select.Option value='LUZ ANGELA FLOREZ'>LUZ ANGELA FLOREZ</Select.Option>
                            <Select.Option value='ERLIAN AVIRAMA'>ERLIAN AVIRAMA</Select.Option>
                            <Select.Option value='LUIS EDUARDO PALOMARES'>LUIS EDUARDO PALOMARES</Select.Option>
                            <Select.Option value='NORELY MANQUILLO'>NORELY MANQUILLO</Select.Option>
                            <Select.Option value='GLORIA CAROLINA QUESADA'>GLORIA CAROLINA QUESADA</Select.Option>
                            <Select.Option value='LUISA VARGAS'>LUISA VARGAS</Select.Option>
                            <Select.Option value='GISELLE DANIELA HERNANDEZ'>GISELLE DANIELA HERNANDEZ</Select.Option>
                            <Select.Option value='KELLY FERNANDA CERQUERA'>KELLY FERNANDA CERQUERA</Select.Option>
                            <Select.Option value='KAREN LOZANO'>KAREN LOZANO</Select.Option>
                            <Select.Option value='LAURA NATALIA MENDEZ'>LAURA NATALIA MENDEZ</Select.Option>
                            <Select.Option value='LUISA CELIS'>LUISA CELIS</Select.Option>
                            <Select.Option value='KELLY CERQUERA'>KELLY CERQUERA</Select.Option>
                            <Select.Option value='REINEL CORDOBA'>REINEL CORDOBA</Select.Option>
                            <Select.Option value='ANONIMO'>ANONIMO</Select.Option>
                            <Select.Option value='DEISI ZARABANDA'>DEISI ZARABANDA</Select.Option>
                            <Select.Option value='EDNA MARGARITA MEJIA'>EDNA MARGARITA MEJIA</Select.Option>
                            <Select.Option value='KEVIN TORRES'>KEVIN TORRES</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Titulo del documento*'
                          validateStatus={this.state.errorTitle && ('error')}
                          help={this.state.errorTitle}
                        >
                          <Input
                            type='text'
                            name='title'
                            disabled
                            placeholder='Ingrese el titulo del documento'
                            onChange={this.onChange}
                            value={this.state.title}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5>Tipo de solicitud</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Creación*'
                          validateStatus={this.state.errorCreatedRequest && ('error')}
                          help={this.state.errorCreatedRequest}
                        >
                          <Input
                            type='text'
                            name='createdRequest'
                            disabled
                            placeholder='Ingrese la creación'
                            onChange={this.onChange}
                            value={this.state.createdRequest}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Código*'
                          validateStatus={this.state.errorCreatedCode && ('error')}
                          help={this.state.errorCreatedCode}
                        >
                          <Input
                            type='text'
                            name='createdCode'
                            placeholder='Ingrese el código'
                            onChange={this.onChange}
                            disabled
                            value={this.state.createdCode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Modificación*'
                          validateStatus={this.state.errorModificationRequest && ('error')}
                          help={this.state.errorModificationRequest}
                        >
                          <Input
                            type='text'
                            name='modificationRequest'
                            placeholder='Ingrese la modificación'
                            onChange={this.onChange}
                            disabled
                            value={this.state.modificationRequest}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Versión*'
                          validateStatus={this.state.errorModificationVersion && ('error')}
                          help={this.state.errorModificationVersion}
                        >
                          <Input
                            type='text'
                            name='modificationVersion'
                            placeholder='Ingrese la versión'
                            onChange={this.onChange}
                            disabled
                            value={this.state.modificationVersion}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Anulación*'
                          validateStatus={this.state.errorAnnulmentRequest && ('error')}
                          help={this.state.errorAnnulmentRequest}
                        >
                          <Input
                            type='text'
                            name='annulmentRequest'
                            placeholder='Ingrese la anulación'
                            onChange={this.onChange}
                            disabled
                            value={this.state.annulmentRequest}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Código*'
                          validateStatus={this.state.errorAnnulmentCode && ('error')}
                          help={this.state.errorAnnulmentCode}
                        >
                          <Input
                            type='text'
                            name='annulmentCode'
                            placeholder='Ingrese el código'
                            onChange={this.onChange}
                            disabled
                            value={this.state.annulmentCode}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Versión*'
                          validateStatus={this.state.errorAnnulmentVersion && ('error')}
                          help={this.state.errorAnnulmentVersion}
                        >
                          <Input
                            type='text'
                            name='annulmentVersion'
                            placeholder='Ingrese la versión'
                            onChange={this.onChange}
                            disabled
                            value={this.state.annulmentVersion}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Descripción / Justificación*'
                          validateStatus={this.state.errorDescription && ('error')}
                          help={this.state.errorDescription}
                        >
                          <Input.TextArea
                            rows={6}
                            name='description'
                            placeholder='Descripción / Justificación'
                            onChange={this.onChange}
                            disabled
                            value={this.state.description}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      {documents.length > 0 && (
                        <Col span={7} offset={1}>
                          <Form.Item
                            label='Documentos'
                          >
                            {documents}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Evaluación*'
                          validateStatus={this.state.errorEvaluation && ('error')}
                          help={this.state.errorEvaluation}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled
                            onChange={(value) => this.setState({'evaluation': value})}
                            value={this.state.evaluation}
                            placeholder='Seleccione una evaluación'
                          >
                            <Select.Option value='Aprobado'>Aprobado</Select.Option>
                            <Select.Option value='No aprobado'>No aprobado</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Conclusiones*' >
                          <Input.TextArea
                            rows={6}
                            name='conclusions'
                            disabled
                            placeholder='Conclusiones'
                            onChange={this.onChange}
                            value={this.state.conclusions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default ChangeNotificationDetail
