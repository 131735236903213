import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from 'reactstrap'
import { getItem } from '../../api/utils'
import Logo from '../../styles/img/oftalmolaser.png'

class Client extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: props.item,
      redirect: undefined,
      profile: getItem('profile')
    }
    this.handleLogout = this.handleLogout.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  componentWillReceiveProps (newProps) {
    if (newProps.item !== this.state.item) {
      this.updateState('item', newProps.item)
    }
  }

  handleLogout () {
    window.localStorage.clear()
    this.updateState('redirect', '/')
  }

  handleRedirect (url) {
    const pathname = window.location.pathname
    if (pathname !== url) {
      this.updateState('redirect', url)
    }
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      )
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='md'
        id='sidenav-main'
      >
        <Container fluid>
          <NavbarBrand className='pt-0'>
            <img
              alt='Oftalmolasersa'
              className='navbar-brand-img'
              src={Logo}
            />
          </NavbarBrand>
          <Collapse navbar>
            <Nav navbar>
              <NavItem key='1'>
                <NavLink
                  onClick={() => this.handleRedirect('/home')}
                  active={this.state.item === 'home'}
                >
                  <i className='ni ni-tv-2 text-blue' />
                  Inicio
                </NavLink>
              </NavItem>
              {this.state.profile === 'Administrador' && (
                <NavItem key='2'>
                  <NavLink
                    onClick={() => this.handleRedirect('/user')}
                    active={this.state.item === 'user'}
                  >
                    <i className='ni ni-single-02 text-yellow' /> Usuario
                  </NavLink>
                </NavItem>
              )}
              {this.state.profile === 'Administrador' && (
                <NavItem key='3'>
                  <NavLink
                    onClick={() => this.handleRedirect('/priority')}
                    active={this.state.item === 'priority'}
                  >
                    <i className='ni ni-check-bold text-primary' /> Prioridades
                  </NavLink>
                </NavItem>
              )}
              {this.state.profile === 'Administrador' && (
                <NavItem key='4'>
                  <NavLink
                    onClick={() => this.handleRedirect('/area')}
                    active={this.state.item === 'area'}
                  >
                    <i className='ni ni-tag text-danger' /> Área
                  </NavLink>
                </NavItem>
              )}
              {this.state.profile === 'Administrador' && (
                <NavItem key='5'>
                  <NavLink
                    onClick={() => this.handleRedirect('/type-requirement')}
                    active={this.state.item === 'requirement'}
                  >
                    <i className='ni ni-bullet-list-67 text-primary' /> Requerimientos
                  </NavLink>
                </NavItem>
              )}
              <NavItem key='6'>
                <NavLink
                  onClick={() => this.handleRedirect('/maintenance')}
                  active={this.state.item === 'maintenance'}
                >
                  <i className='ni ni-album-2 text-info' /> Mantenimientos
                </NavLink>
              </NavItem>
              <NavItem key='7'>
                <NavLink
                  onClick={() => this.handleRedirect('/nonconforming')}
                  active={this.state.item === 'nonconforming'}
                >
                  <i className='ni ni-collection text-yellow' /> Salidas no conforme
                </NavLink>
              </NavItem>
              {<NavItem key='8'>
                <NavLink
                  onClick={() => this.handleRedirect('/change-modification')}
                  active={this.state.item === 'changeModification'}
                >
                  <i className='ni ni-paper-diploma text-danger' /> Solicitud de modificación de documentos
                </NavLink>
              </NavItem>}
              {(this.state.profile === 'Administrador' || this.state.profile === 'Solucionador') && (
                <NavItem key='9'>
                  <NavLink
                    onClick={() => this.handleRedirect('/report')}
                    active={this.state.item === 'report'}
                  >
                    <i className='ni ni-calendar-grid-58 text-success' /> Reportes
                  </NavLink>
                </NavItem>
              )}
              {(this.state.profile === 'Administrador' || this.state.profile === 'Solucionador') && (
                <NavItem key='10'>
                  <NavLink
                    onClick={() => this.handleRedirect('/nonconforming/report')}
                    active={this.state.item === 'report-nonconforming'}
                  >
                    <i className='ni ni-calendar-grid-58 text-success' /> Reportes salidas no conforme
                  </NavLink>
                </NavItem>
              )}
              {(this.state.profile === 'Administrador' || this.state.profile === 'Solucionador') && (
                <NavItem key='11'>
                  <NavLink
                    onClick={() => this.handleRedirect('/change-modification/report')}
                    active={this.state.item === 'report-change-modification'}
                  >
                    <i className='ni ni-calendar-grid-58 text-success' /> Reportes de solicitud de modificación de documentos
                  </NavLink>
                </NavItem>
              )}
              <NavItem key='12'>
                <NavLink
                  onClick={this.handleLogout}
                >
                  <i className='ni ni-button-power text-warning' /> Salir
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default Client
