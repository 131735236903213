import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Upload,
  Icon
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'
import config from '../../api/config'
import { getItem } from '../../api/utils'

class NonconformingCreate extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      type: undefined,
      errorType: undefined,
      process: undefined,
      errorProcess: undefined,
      by: undefined,
      errorBy: undefined,
      description: undefined,
      errorDescription: undefined,
      redirect: undefined,
      documents: [],
      requirements: [],
      profile: getItem('profile')
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  createRow (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.clearErrors()
    const response = await API.nonconforming.create({
      type: this.state.type,
      by: this.state.by,
      description: this.state.description,
      process: this.state.process,
      documents: this.state.documents
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de una solicitud',
        content: `La solicitud de producto o servicio no conforme fue creado exitosamente`
      })
      this.clearFields()
      this.onBack()
    }
    if (response.errors) {
      this.makeErrors(response.errors)
    }
  }

  makeErrors (error) {
    if (error.type) {
      this.updateState('errorType', error.type.message)
    }
    if (error.by) {
      this.updateState('errorBy', error.by.message)
    }
    if (error.description) {
      this.updateState('errorDescription', error.description.message)
    }
    if (error.process) {
      this.updateState('errorProcess', error.process.message)
    }
  }

  clearFields () {
    this.updateState('name', undefined)
    this.updateState('area', undefined)
    this.updateState('priority', undefined)
    this.clearErrors()
  }

  clearErrors () {
    this.updateState('errorName', undefined)
    this.updateState('errorArea', undefined)
  }

  async handleChangeType (value) {
    this.updateState('type', value)
  }

  async onBack () {
    this.updateState('redirect', '/nonconforming')
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='nonconforming' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Nueva solicitud de producto o servicio no conforme</h3>
                        <h6 className='text-uppercase text-warning ls-1 mb-1'>
                          * Son campos obligatorios
                        </h6>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <label>Código: C-FO-061</label>
                      </Col>
                      <Col>
                        <label>Versión: 1.2</label>
                      </Col>
                      <Col>
                        <label>Vigencia: Diciembre de 2012</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Tipo*'
                          validateStatus={this.state.errorType && ('error')}
                          help={this.state.errorType}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeType}
                            value={this.state.type}
                            placeholder='Seleccione un tipo'
                          >
                            <Select.Option value='service'>Servicio</Select.Option>
                            <Select.Option value='product'>Producto</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Identificado por*'
                          validateStatus={this.state.errorBy && ('error')}
                          help={this.state.errorBy}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => this.setState({'by': value})}
                            value={this.state.by}
                            placeholder='Seleccione un usuario'
                          >
                            <Select.Option value='ADRIANA LORENA RAMIREZ BARRERA'>ADRIANA LORENA RAMIREZ BARRERA</Select.Option>
                            <Select.Option value='CARLA FERNANDA CHILA MORENO'>CARLA FERNANDA CHILA MORENO</Select.Option>
                            <Select.Option value='CLAUDIA VARGAS'>CLAUDIA VARGAS</Select.Option>
                            <Select.Option value='DIANA CONSTANZA PERDOMO FARFAN'>DIANA CONSTANZA PERDOMO FARFAN</Select.Option>
                            <Select.Option value='ERIKA JULIETH VARGAS ROJAS'>ERIKA JULIETH VARGAS ROJAS</Select.Option>
                            <Select.Option value='JENNY MARCELA CALDERON'>JENNY MARCELA CALDERON</Select.Option>
                            <Select.Option value='LIDA CONSTANZA AGUIRRE'>LIDA CONSTANZA AGUIRRE</Select.Option>
                            <Select.Option value='MARIA CAMILA CASTILLO FARFAN'>MARIA CAMILA CASTILLO FARFAN</Select.Option>
                            <Select.Option value='MARIA YANETH CALDON'>MARIA YANETH CALDON</Select.Option>
                            <Select.Option value='MARITZA ANDREA DIAZ MONTEALEGRE'>MARITZA ANDREA DIAZ MONTEALEGRE</Select.Option>
                            <Select.Option value='MARITZA FERNANDA QUILA MOYANO'>MARITZA FERNANDA QUILA MOYANO</Select.Option>
                            <Select.Option value='NORMA DERLY RODRIGUEZ NAVARRO'>NORMA DERLY RODRIGUEZ NAVARRO</Select.Option>
                            <Select.Option value='PAOLA ANDREA VASQUEZ LASSO'>PAOLA ANDREA VASQUEZ LASSO</Select.Option>
                            <Select.Option value='SANDRA SANCHEZ'>SANDRA SANCHEZ</Select.Option>
                            <Select.Option value='SULLY XIMENA TORRES LAVAO'>SULLY XIMENA TORRES LAVAO</Select.Option>
                            <Select.Option value='YINA MILDRED CUELLAR'>YINA MILDRED CUELLAR</Select.Option>
                            <Select.Option value='YOLANDA PINTO CHAGUALA'>YOLANDA PINTO CHAGUALA</Select.Option>
                            <Select.Option value='YUDDY ARAGONEZ'>YUDDY ARAGONEZ</Select.Option>
                            <Select.Option value='ANA MERCEDESDELGADO PATARROYO'>ANA MERCEDESDELGADO PATARROYO</Select.Option>
                            <Select.Option value='EDNA MARIA SANCHEZ'>EDNA MARIA SANCHEZ</Select.Option>
                            <Select.Option value='LEIDY JOHANA MORALES'>LEIDY JOHANA MORALES</Select.Option>
                            <Select.Option value='ESPERANZA GOMEZ'>ESPERANZA GOMEZ</Select.Option>
                            <Select.Option value='AVILES LONDOÑO LINA MARIA'>AVILES LONDOÑO LINA MARIA</Select.Option>
                            <Select.Option value='BRAVO GONZALEZ CAROLINA'>BRAVO GONZALEZ CAROLINA</Select.Option>
                            <Select.Option value='CARDOZO TORRES LUISA FERNANDA'>CARDOZO TORRES LUISA FERNANDA</Select.Option>
                            <Select.Option value='CELIS VARGAS LUISA FERNANDA'>CELIS VARGAS LUISA FERNANDA</Select.Option>
                            <Select.Option value='ASESOR DE SEGURIDAD Y SALUD EN EL TRABAJO'>ASESOR DE SEGURIDAD Y SALUD EN EL TRABAJO</Select.Option>
                            <Select.Option value='GALINDO CARDOZO CRISTIAN'>GALINDO CARDOZO CRISTIAN</Select.Option>
                            <Select.Option value='PERDOMO PEREZ ALEJANDRA'>PERDOMO PEREZ ALEJANDRA</Select.Option>
                            <Select.Option value='RONAR DARIO OROZCO ULCHUR'>RONAR DARIO OROZCO ULCHUR</Select.Option>
                            <Select.Option value='RUIZ MONROY DANIEL ANDRES'>RUIZ MONROY DANIEL ANDRES</Select.Option>
                            <Select.Option value='SANCHEZ MORALES EDNA MARIA'>SANCHEZ MORALES EDNA MARIA</Select.Option>
                            <Select.Option value='LEIVIN MARIA BLANCO'>LEIVIN MARIA BLANCO</Select.Option>
                            <Select.Option value='LUIS EDUARDO PALOMARES'>LUIS EDUARDO PALOMARES</Select.Option>
                            <Select.Option value='GLORIA CAROLINA QUESADA'>GLORIA CAROLINA QUESADA</Select.Option>
                            <Select.Option value='KAROL PAOLA CONDE'>KAROL PAOLA CONDE</Select.Option>
                            <Select.Option value='GISELLE DANIELA HERNANDEZ'>GISELLE DANIELA HERNANDEZ</Select.Option>
                            <Select.Option value='KELLY FERNANDA CERQUERA'>KELLY FERNANDA CERQUERA</Select.Option>
                            <Select.Option value='KAREN LOZANO'>KAREN LOZANO</Select.Option>
                            <Select.Option value='LAURA NATALIA MENDEZ'>LAURA NATALIA MENDEZ</Select.Option>
                            <Select.Option value='LEIVIN MARIA BLANCO'>LEIVIN MARIA BLANCO</Select.Option>
                            <Select.Option value='JHON JAMES URBANO'>JHON JAMES URBANO</Select.Option>
                            <Select.Option value='KARLA SOLANO'>KARLA SOLANO</Select.Option>
                            <Select.Option value='LUZ ANGELA FLOREZ'>LUZ ANGELA FLOREZ</Select.Option>
                            <Select.Option value='ERLIAN AVIRAMA'>ERLIAN AVIRAMA</Select.Option>
                            <Select.Option value='LUIS EDUARDO PALOMARES'>LUIS EDUARDO PALOMARES</Select.Option>
                            <Select.Option value='NORELY MANQUILLO'>NORELY MANQUILLO</Select.Option>
                            <Select.Option value='GLORIA CAROLINA QUESADA'>GLORIA CAROLINA QUESADA</Select.Option>
                            <Select.Option value='LUISA VARGAS'>LUISA VARGAS</Select.Option>
                            <Select.Option value='GISELLE DANIELA HERNANDEZ'>GISELLE DANIELA HERNANDEZ</Select.Option>
                            <Select.Option value='KELLY FERNANDA CERQUERA'>KELLY FERNANDA CERQUERA</Select.Option>
                            <Select.Option value='KAREN LOZANO'>KAREN LOZANO</Select.Option>
                            <Select.Option value='LAURA NATALIA MENDEZ'>LAURA NATALIA MENDEZ</Select.Option>
                            <Select.Option value='LUISA CELIS'>LUISA CELIS</Select.Option>
                            <Select.Option value='KELLY CERQUERA'>KELLY CERQUERA</Select.Option>
                            <Select.Option value='REINEL CORDOBA'>REINEL CORDOBA</Select.Option>
                            <Select.Option value='ANONIMO'>ANONIMO</Select.Option>
                            <Select.Option value='DEISI ZARABANDA'>DEISI ZARABANDA</Select.Option>
                            <Select.Option value='EDNA MARGARITA MEJIA'>EDNA MARGARITA MEJIA</Select.Option>
                            <Select.Option value='KEVIN TORRES'>KEVIN TORRES</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Proceso*'
                          validateStatus={this.state.errorProcess && ('error')}
                          help={this.state.errorProcess}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => this.setState({'process': value})}
                            value={this.state.process}
                            placeholder='Seleccione un proceso'
                          >
                            <Select.Option value='Consulta externa'>Consulta externa</Select.Option>
                            <Select.Option value='Apoyo diagnóstico'>Apoyo diagnóstico</Select.Option>
                            <Select.Option value='Cirugía'>Cirugía</Select.Option>
                            <Select.Option value='Gestión de la información'>Gestión de la información</Select.Option>
                            <Select.Option value='Gestión de mantenimiento'>Gestión de mantenimiento</Select.Option>
                            <Select.Option value='Direccionamiento estratégico'>Direccionamiento estratégico</Select.Option>
                            <Select.Option value='Gestión de suministro'>Gestión de suministro</Select.Option>
                            <Select.Option value='Gestión de talento humano'>Gestión de talento humano</Select.Option>
                            <Select.Option value='Gestión de calidad y mejoramiento'>Gestión de calidad y mejoramiento</Select.Option>
                            <Select.Option value='Gestión contable y financiera'>Gestión contable y financiera</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Descripción de la no conformidad*'
                          validateStatus={this.state.errorDescription && ('error')}
                          help={this.state.errorDescription}
                        >
                          <Input.TextArea
                            rows={6}
                            name='description'
                            placeholder='Descripción de la no conformidad'
                            onChange={this.onChange}
                            value={this.state.description}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Item
                          label='Documentos'
                        >
                          <Upload
                            name='documents'
                            action={`${config.URL}/maintenance/maintenance/create/upload`}
                            headers={config.configWithToken().headers}
                            onChange={(info) => {
                              if (info.file.status === 'done') {
                                let documents = this.state.documents
                                documents.push(info.file.response.path)
                                this.setState({
                                  documents: documents
                                })
                              }
                            }}
                            onRemove={(info) => {
                              let documents = this.state.documents
                              documents.pop(info.response.path)
                              this.setState({
                                documents: documents
                              })
                            }}
                          >
                            <Button>
                              <Icon type='upload' /> Subir documento
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default NonconformingCreate
